import styled from "styled-components"

import { FormControl, InputLabel, Select, TextField } from "@material-ui/core"

import {
  countryData,
  getProvinceArray,
} from "src/components/Account/BillingPortal/countryData"
import { IAddress } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

import { useHomeAddressForm } from "./useHomeAddressForm"

export function HomeAddressForm({
  onSubmit,
  initialAddress,
  error,
  formId,
}: {
  onSubmit: (formData: IAddress) => void
  initialAddress?: IAddress
  error?: boolean
  formId?: string
}) {
  const { address, handleInputChange } = useHomeAddressForm(initialAddress)
  const provinces = getProvinceArray(address.country)

  const { t, langKeys } = useTranslate()

  return (
    <div>
      <FormBox
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit(address)
        }}
        id={formId}
      >
        <FormControl variant="outlined" fullWidth>
          <InputLabel
            htmlFor="country"
            required
            style={{ background: "white" }}
          >
            {t(langKeys.address_form_country)}
          </InputLabel>
          <Select
            autoComplete="country"
            native
            value={address?.country}
            required
            inputProps={{
              id: "country",
            }}
            onChange={(e) => {
              handleInputChange({ country: e.target.value as string })
            }}
          >
            <option value="" hidden />
            {countryData.map((country) => (
              <option value={country.code} key={country.code}>
                {country.label}
              </option>
            ))}
          </Select>
        </FormControl>

        <TextField
          label={t(langKeys.address_form_street_name_1)}
          name="street_name1"
          fullWidth
          required
          autoComplete="street_name1"
          value={address?.street_name1}
          onChange={(e) => handleInputChange({ street_name1: e.target.value })}
        />

        <TextField
          label={t(langKeys.address_form_street_name_2)}
          name="street_name2"
          autoComplete="street_name2"
          fullWidth
          value={address?.street_name2}
          onChange={(e) => handleInputChange({ street_name2: e.target.value })}
        />
        <Area>
          <TextField
            label={t(langKeys.address_form_city)}
            name="city"
            autoComplete="city"
            fullWidth
            required
            value={address?.city}
            onChange={(e) => handleInputChange({ city: e.target.value })}
          />
          {provinces.length > 0 && (
            <FormControl variant="outlined" fullWidth>
              <InputLabel
                htmlFor="state"
                style={{ background: "white" }}
                required
              >
                {t(langKeys.address_form_state)}
              </InputLabel>
              <Select
                autoComplete="state"
                native
                value={address?.region}
                required
                inputProps={{
                  id: "state",
                }}
                onChange={(e) => {
                  handleInputChange({
                    region: e.target.value as string,
                  })
                }}
              >
                <option value="" hidden></option>
                {provinces.map((province) => (
                  <option value={province.code} key={province.code}>
                    {province.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}

          <TextField
            label={t(langKeys.address_form_postcode)}
            type="post_code"
            name="post_code"
            fullWidth
            required
            autoComplete="post_code"
            value={address?.post_code}
            onChange={(e) => handleInputChange({ post_code: e.target.value })}
          />
        </Area>
      </FormBox>
      {error && (
        <AlertBox type="error">
          {t(langKeys.failed_something_went_wrong)}
        </AlertBox>
      )}
    </div>
  )
}

const FormBox = styled.form`
  display: grid;
  gap: ${spacing.L};
  grid-template-columns: 1fr;
  padding: ${spacing.XS2};
`

const Area = styled.div`
  display: grid;
  gap: ${spacing.L};
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`

const AlertBox = styled(MBanner)`
  margin-bottom: ${spacing.L};
`
